import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    children:[]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/show',
    name: 'show',
    component: () => import('../views/show.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history', // 使用 history 模式
})

router.beforeEach((to, from, next) => {
  // 检查是否是刷新事件
  const isPageRefresh = to.matched.length === 0
  if (isPageRefresh && to.path !== '/') {
    // 如果是子页面刷新，重定向到根路径
    next('/')
  } else {
    next()
  }
})

export default router
